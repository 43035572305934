<template>
  <div class="profile container">
    <div class="col-12">
      <div class="tile mb-2">
        <ul id="profileTabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Profile
            </button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="profileContent">
        <div
          class="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div class="tile">
            <h5 class="tile-title">Edit Account</h5>
            <div class="tile-body">
              <ValidationObserver
                tag="form"
                v-slot="{ handleSubmit }"
                ref="editUserForm"
              >
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <ValidationProvider
                        tag="div"
                        rules="required"
                        name="Full Name"
                        v-slot="{ errors }"
                        class="mb-3"
                      >
                        <label for="fullname" class="col-form-label"
                          >Full Name:</label
                        >
                        <input
                          type="text"
                          v-model="editUser_data.name"
                          id="fullname"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0],
                          }"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <ValidationProvider
                        tag="div"
                        rules="required|email"
                        name="Email"
                        v-slot="{ errors }"
                        class="mb-3"
                      >
                        <label for="email" class="col-form-label">Email:</label>
                        <input
                          type="email"
                          v-model="editUser_data.email"
                          id="email"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] || emailExists,
                          }"
                        />
                        <span class="error">{{ errors[0] }}</span>
                        <span v-if="emailExists" class="error"
                          >Email already exists</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <div class="mb-3">
                        <label for="pc-ip" class="col-form-label">PC IP:</label>
                        <input
                          type="text"
                          v-model="editUser_data.pcIP"
                          id="pc-ip"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <div class="mb-3">
                        <label for="vpn-username" class="col-form-label"
                          >VPN Username:</label
                        >
                        <input
                          type="text"
                          v-model="editUser_data.vpnUser"
                          id="vpn-username"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="mb-3">
                        <label for="vpn-password" class="col-form-label"
                          >VPN Password:</label
                        >
                        <input
                          type="text"
                          v-model="editUser_data.vpnPassword"
                          id="vpn-password"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <ValidationProvider
                        tag="div"
                        rules="required"
                        name="Title"
                        v-slot="{ errors }"
                        class="mb-3"
                      >
                        <label for="title" class="col-form-label">Title:</label>
                        <input
                          type="text"
                          v-model="editUser_data.title"
                          id="title"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0],
                          }"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="d-grid d-md-flex justify-content-md-end">
                  <button
                    class="btn btn-secondary me-md-2"
                    type="button"
                    @click.prevent="resetForm"
                  >
                    Reset
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click.prevent="handleSubmit(editUser)"
                  >
                    Update User
                    <loader :isLoading="isLoading"></loader>
                  </button>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tile">
              <h5 class="tile-title">Edit Password</h5>
              <div class="tile-body">
                <ValidationObserver
                  tag="form"
                  v-slot="{ handleSubmit }"
                  ref="editUserPasswordForm"
                >
                  <div class="form-group">
                    <ValidationProvider
                      tag="div"
                      rules="required|confirmed:confirmPassword"
                      name="Password"
                      v-slot="{ errors }"
                      class="mb-3"
                    >
                      <label for="password" class="col-form-label"
                        >Password:</label
                      >
                      <input
                        type="password"
                        v-model="editUser_data.password"
                        id="password"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0],
                        }"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      tag="div"
                      vid="confirmPassword"
                      rules="required"
                      name="Confirm Password"
                      v-slot="{ errors }"
                      class="mb-3"
                    >
                      <label for="confirm-password" class="col-form-label"
                        >Repeat Password:</label
                      >
                      <input
                        type="password"
                        v-model="editUser_data.confirmPassword"
                        id="confirm-password"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0],
                        }"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="d-grid d-md-flex justify-content-md-end">
                    <button
                      class="btn btn-secondary me-md-2"
                      type="button"
                      @click.prevent="resetForm(true)"
                    >
                      Reset
                    </button>
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click.prevent="handleSubmit(editUserPassword)"
                    >
                      Update Password
                      <loader :isLoading="isLoading"></loader>
                    </button>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "../components/loader";
import { getters, mutations } from "../store";
export default {
  data() {
    return {
      emailExists: false,
      editUser_data: {
        _id: "",
        name: "",
        email: "",
        pcIP: "",
        vpnUser: "",
        vpnPassword: "",
        title: "",
        role: 0,
        password: "",
        confirmPassword: "",
      },
      isLoading: false,
    };
  },
  components: {
    loader,
  },
  computed: {
    userLoggedIn() {
      return getters.userLoggedIn();
    },
  },
  watch: {
    userLoggedIn: {
      handler(user) {
        this.editUser_data = { ...user };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    editUser(resetPassword = false) {
      this.isLoading = true;
      this.$axios
        .patch(`/users/${this.editUser_data._id}`, this.editUser_data)
        .then(({ data }) => {
          if (data.success) {
            if (resetPassword) {
              window.localStorage.clear();
              mutations.setLoggedInUser();
              this.$router.push("/login");
            } else {
              mutations.updateUser(this.editUser_data);
              mutations.setLoggedInUser(this.editUser_data);
            }
            window.scrollTo(0, 0);
            this.isLoading = false;
            mutations.showToast(data);
          } else if (!resetPassword && data.message.code == 11000) {
            this.emailExists = true;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    editUserPassword() {
      this.editUser(true);
    },
    resetForm(resetPassword = false) {
      this.$nextTick(() => {
        this.isLoading = false;
        this.emailExists = false;
        this.editUser_data = getters.userLoggedIn();
        if (resetPassword) {
          this.editUser_data.password = "";
          this.editUser_data.confirmPassword = "";
          this.$refs.editUserPasswordForm.reset();
        }
      });
    },
  },
};
</script>
